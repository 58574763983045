import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../components/footer'
import Menu from '../components/menu'
import PostsGrid from '../components/posts-grid'
import FeaturedImage from '../components/featured-image'
import FormattedText from '../components/formatted-text'
import Seo from '../components/seo'
import PaginationBar from '../components/pagination-bar'
import {getPagination, getDateSorted} from '../utils/utils'

const Author = ({ data }) => {
    const author = data.strapiAuthors
    const [currentPage, setCurrentPage] = useState(1)
    const allposts = data.allStrapiPosts.edges
    const allStories = data.allStrapiStories.edges
    const postAndStories = getDateSorted(allposts.concat(allStories))
    const itemsPerPage = 9
    const pagesAmount = (Math.ceil(postAndStories.length / itemsPerPage))
    return (
    <>
    <Seo/>
    <Container fluid="md">
        <Row>
        <Col>
            <Menu/>
        </Col>
        </Row>
        <Row id="content-row">
        <Col>
        <Row>
        <FeaturedImage alt={author.title || ""} image={author.image || ""} post ={true}/>
        </Row>
        <FormattedText title= {author.name || ""} content={author.bio || ""} />
        {((author.email !== null && author.email !== "") ?<p><i class="fas fa-envelope" id="card-icon"></i>{` `}{author.email}{`  `}</p>:<></>)}
        {((author.donation !== null && author.donation !== "") ?<p><i class="fas fa-coins" id="card-icon"></i>{` `}<a href={author.donation}  target="_blank" rel="noreferrer">Apoya al autor</a>   <Link to={`/pages/donar`}>{`      `}¿Por qué apoyar?</Link></p>:<></>)}
        {author.links?.map((link)=>(
        <p><i class="fas fa-link" id="card-icon"></i>{` `}<a href={link.url} target="_blank" rel="noreferrer">{link.name}</a>{`  `}</p>
        ))}

        </Col>
        </Row>
        <Row id="more-posts-row">
        <h4>Publicaciones</h4>
        </Row>
        <Row>
        <PostsGrid posts={getPagination(postAndStories,itemsPerPage,currentPage)} />
        </Row>
        <Row id ="pagination-row">
        <PaginationBar currentPage = {currentPage} setCurrentPage={setCurrentPage} pagesAmount={pagesAmount}/>
        </Row>
        <Footer/>
    </Container>
    </>

            
    )
}

/*

 */

export const query = graphql`
  query AuthorsQuery($slug: String!){
    strapiAuthors (slug: {eq: $slug}){
        name
        slug
        bio
        email
        donation
        image {
            localFile {
              publicURL
              childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300)
              }
            }
        }
        links{
          name
          url
        }
    }
    allStrapiPosts (filter:{author:{slug: {eq: $slug}}}){
        edges{
            node{
              id
              title
              slug
              date
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              author {
                name
                slug
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
              category {
                name
                slug
              }
              tags {
                name
                slug
              }
            }
        }
    }
    allStrapiStories (filter:{author:{slug: {eq: $slug}}}){
      edges{
          node{
            id
            title
            slug
            date
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            author {
              name
              slug
            }
            category {
              name
              slug
            }
            tags {
              name
              slug
            }
          }
      }
    }
  }
`

export default Author